<template>
  <vue-final-modal class="modal-cancel-unsettled-bonus">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">{{ getContent(props.currentLocaleData, props.defaultLocaleData, 'title') }}</div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('cancel-unsettled-bonus')" />
      </div>
    </div>

    <div class="vfm__body">
      <atomic-icon id="big-info" class="modal-cancel-unsettled-bonus__icon" filled />

      <div class="modal-cancel-unsettled-bonus__subtitle">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'subtitle') }}
      </div>

      <div class="modal-cancel-unsettled-bonus__description">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'description') }}
      </div>

      <div class="modal-cancel-unsettled-bonus__wager">
        <div class="modal-cancel-unsettled-bonus__wager-content">
          <span class="modal-cancel-unsettled-bonus__wager-label">
            {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'wageredLabel') }}
            (x{{ activePlayerBonus?.wagerCasino }}):
          </span>

          <span class="modal-cancel-unsettled-bonus__wager-value">
            {{ formatAmount(activePlayerBonus?.currency, activePlayerBonus?.currentWagerAmount) }}
          </span>
        </div>

        <bonuses-progress v-if="activePlayerBonus" :progress="activePlayerBonus.currentWagerPercentage" />
      </div>

      <div class="modal-cancel-unsettled-bonus__actions">
        <button-base type="secondary-3" size="md" :isDisabled="bonusProcessing" @click="cancelBonus">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'cancelButton') }}
        </button-base>

        <button-base type="secondary-1" size="md" @click="closeModal('cancel-unsettled-bonus')">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'confirmButton') }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIConfirmBonusActionModal } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<CIConfirmBonusActionModal>;
    defaultLocaleData: Maybe<CIConfirmBonusActionModal>;
  }>();

  const bonusStore = useBonusStore();
  const { processingBonuses } = storeToRefs(bonusStore);
  const { activePlayerBonus } = bonusStore;
  const { getContent, formatAmount } = useProjectMethods();
  const { closeModal } = useModalStore();
  const { alertsData, defaultLocaleAlertsData } = useGlobalStore();
  const { showAlert } = useLayoutStore();

  const bonusProcessing = computed(() => processingBonuses.value.includes(activePlayerBonus?.id || '-1'));
  const { cancelPlayerBonus } = useCoreBonusApi();
  const cancelBonus = async (): Promise<void> => {
    if (bonusProcessing.value || !activePlayerBonus?.id) return;
    processingBonuses.value.push(activePlayerBonus.id);

    try {
      await cancelPlayerBonus(activePlayerBonus.id);
      processingBonuses.value = processingBonuses.value.filter(id => id !== activePlayerBonus.id);
      await closeModal('cancel-unsettled-bonus');
    } catch {
      processingBonuses.value = processingBonuses.value.filter(id => id !== activePlayerBonus.id);
      showAlert(alertsData?.global?.somethingWrong || defaultLocaleAlertsData?.global?.somethingWrong);
    }
  };
</script>

<style src="~/assets/styles/components/modal/cancel-unsettled-bonus.scss" lang="scss" />
